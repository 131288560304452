import "./Consultas.css"
import consultoriotijuca from "../../assets/consultoriotijuca.mp4"
import consult1 from "../../assets/consult1.webp"
import local from '../../assets/local.webp'


function Consultas() {
    return (
        <section className="consultasContainer">
            <div className="consultasItens">
                <div className="consultasConteudo">
                    <h3>Consultório</h3>
                    <p>
                        No meu consultório, conto com uma infraestrutura completa para oferecer um atendimento seguro, confortável e personalizado. O espaço foi planejado cuidadosamente para criar um ambiente agradável e esteticamente atraente, com um toque de tranquilidade e serenidade para que meus pacientes se sintam em casa. Afinal, eu sei que a visita ao psicólogo pode ser um momento delicado e que a criação de um ambiente acolhedor é fundamental para o sucesso do tratamento.
                        Faço questão de oferecer uma recepção acolhedora e atenciosa para receber meus pacientes com o máximo de conforto e confiança. Afinal, acredito que a empatia e a compreensão são fundamentais para estabelecer uma relação de confiança e parceria com aqueles que me procuram em busca de ajuda.
                    </p> <br />

                    <div className="locais">

                    <div className="localTijuca">
                    <img className="local" src={local} alt="local" loading="lazy" />
                    <p><strong>Tijuca:</strong> <br/> Rua Des. Izidro, 18</p>
                    </div>
                    </div>
                </div>
                <div className="consultorioVideo">
    <video controls>
        <source src={consultoriotijuca} type="video/mp4" />
        Seu navegador não suporta o elemento de vídeo.
    </video>

</div>
            </div>

            

            <div className="consultItens">
            <div className="consultImg">
                    <img src={consult1} alt="consultório" loading="lazy"/>
                </div>
                
                <div className="consultConteudo">
                    <h3>Consultas</h3>
                    <p>
                        Ofereço atendimento tanto no meu consultório físico quanto de forma remota, por meio de tecnologia adequada e em conformidade com as normas éticas e de segurança da profissão. Eu valorizo a escolha do paciente em relação à modalidade de atendimento, seja presencial ou online, buscando sempre garantir o máximo de conforto e flexibilidade em minha prática clínica. Para mim, é importante oferecer um atendimento acessível e adequado às necessidades individuais de cada paciente. Com a opção do atendimento remoto, posso oferecer mais flexibilidade aos meus pacientes, permitindo que recebam o tratamento de que precisam, independente de onde estejam. Claro, sempre mantendo a privacidade, segurança e confidencialidade dos dados e informações dos pacientes.
                    </p>
                </div>
                
            </div>
        </section>
    )
}

export default Consultas